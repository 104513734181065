import React, { useEffect, useRef, useState, useContext } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import { Configure, Index } from 'react-instantsearch/dom'
import orderBy from 'lodash.orderby'
import { connectStateResults } from 'react-instantsearch/connectors'
import { NODE_TYPES } from 'utils/constants'
import ConnectedSearchBox from './ConnectedSearchBox'
import SearchResults from './SearchResults'
import { ResultsWrapper, ResultItemSuffix, SearchWrapper } from './styles'
import { SessionContext } from 'components/AuthContext'

function Search({ defaultFilter, defaultSearchTerm, isFocusedOnLoad, resultsPosition, toggleSearch }) {
	const hitsPerPage = useRef(3)
	const [selectedFilter, setSelectedFilter] = useState(null)
	const [isMoreResultsHidden, setIsMoreResultsHidden] = useState(true)
	const [searchTerm, setSearchTerm] = useState(null)
	const resultsRef = useRef(null)
	const AllResults = connectStateResults((props) => {
		// eslint-disable-next-line react/prop-types
		const { allSearchResults, children } = props
		const noResults =
			allSearchResults &&
			Object.values(allSearchResults).reduce((acc, results) => results.nbHits && results.nbHits === 0, false)
		return noResults ? null : children
	})

	const IndexResults = connectStateResults(
		({ children, resetSearchForm, searchResults, searchState, selectedFilter }) => {
			if (searchState && searchState.query && searchResults) {
				const { index, hits } = searchResults
				let selectedIndex = NODE_TYPES.DISPENSARIES
				if (
					selectedFilter === NODE_TYPES.EDIBLE ||
					selectedFilter === NODE_TYPES.CONCENTRATE ||
					selectedFilter === NODE_TYPES.FLOWER
				) {
					selectedIndex = NODE_TYPES.INVENTORY
				} else {
					selectedIndex = selectedFilter
				}
				if (selectedIndex === index || selectedFilter === null) {
					return SearchResults({
						clearSearchState: () => {
							searchState.query = ''
							resetSearchForm()
							return true
						},
						index,
						hits:
							selectedIndex === NODE_TYPES.INVENTORY && searchState.query !== ''
								? orderBy(
										hits.filter((hit) => hit.category.toLowerCase() === selectedFilter),
										['category', 'name'],
										['asc']
								  )
								: orderBy(hits, ['category', 'name'], ['asc']),
					})
				}
				return null
			}
			return searchState && searchState.query && searchResults ? children : null
		}
	)

	useEffect(() => {
		// handles getting defaults from a querystring paramenter
		if (defaultFilter || defaultSearchTerm) {
			hitsPerPage.current = 50
		}
		if (defaultFilter) {
			setSelectedFilter(defaultFilter)
		}
		if (defaultSearchTerm) {
			setSearchTerm(defaultSearchTerm)
		}
	}, [defaultFilter, defaultSearchTerm])

	const setFilter = (filter) => {
		setSelectedFilter(selectedFilter === filter ? null : filter)
	}

	const setParentSearchTerm = (searchTerm) => {
		setSearchTerm(searchTerm)
	}

	const hideShowResults = (isMoreResultsHidden) => {
		setIsMoreResultsHidden(isMoreResultsHidden)
	}

	const resetSearchForm = () => {
		hideShowResults(true)
		setSearchTerm(null)
		if (toggleSearch) {
			toggleSearch()
		}
	}

	return (
		<SearchWrapper>
			<ConnectedSearchBox
				isFocusedOnLoad={isFocusedOnLoad}
				selectedFilter={selectedFilter}
				setIsMoreResultsHidden={hideShowResults}
				setParentSearchTerm={setParentSearchTerm}
				setSelectedFilter={setFilter}
				defaultSearchTerm={defaultSearchTerm}
			/>
			<ResultsWrapper ref={resultsRef} isShowingResults={searchTerm !== null} resultsPosition={resultsPosition}>
				<AllResults>
					<Index indexName={NODE_TYPES.INVENTORY}>
						<Configure aroundLatLngViaIP getRankingInfo hitsPerPage={hitsPerPage.current + 2} />
						<IndexResults resetSearchForm={resetSearchForm} selectedFilter={selectedFilter} />
					</Index>
					<Index indexName={NODE_TYPES.DISPENSARIES}>
						<Configure aroundLatLngViaIP getRankingInfo hitsPerPage={hitsPerPage.current} />
						<IndexResults resetSearchForm={resetSearchForm} selectedFilter={selectedFilter} />
					</Index>
					{/* <Index indexName={NODE_TYPES.STRAINS}>
						<Configure aroundLatLngViaIP={false} getRankingInfo hitsPerPage={hitsPerPage.current} />
						<IndexResults resetSearchForm={resetSearchForm} selectedFilter={selectedFilter} />
					</Index> */}
					{!isMoreResultsHidden && (
						<Link state={{ selectedFilter, searchTerm }} to={'/search'}>
							<ResultItemSuffix key="lastone">{`More Results`}</ResultItemSuffix>
						</Link>
					)}
				</AllResults>
			</ResultsWrapper>
		</SearchWrapper>
	)
}

Search.propTypes = {
	defaultFilter: PropTypes.string,
	defaultSearchTerm: PropTypes.string,
	isFocusedOnLoad: PropTypes.bool,
	resultsPosition: PropTypes.string,
	toggleSearch: PropTypes.func,
}

Search.defaultProps = {
	isFocusedOnLoad: false,
	defaultFilter: null,
	defaultSearchTerm: null,
	resultsPosition: 'relative',
	toggleSearch: undefined,
}
export default Search
